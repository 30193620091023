import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Lottie from "lottie-react";
import { db } from "../firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import axios from "axios";
import "./style.scss";
import expandLottie from "./expand.json";
import loadingLottie from "./loading.json";
import "./animations.scss";
import FloatingImages from "./FloatingImages";
import DynamicPhotoWall from "./DynamicWall";

const initialSettings = {
  background: "",
  showQr: true,
  overlay: 0.5,
  imageDuration: 5,
  filterBlur: "blur(15px)",
  qrCodePosition: "left",
  eventPlan: "Free",
  logo: "",
  dynamicWall: false,
  floatingImages: false,
};

const initialHighLight = {
  highLightImg: "",
  message: "",
};

const Photowall = React.memo(({ dynamicId }) => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(initialSettings);
  const [highLight, setHighLight] = useState(initialHighLight);
  const [qrCode, setQrCode] = useState("");
  const [error, setError] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fade, setFade] = useState(true);

  const photowallRef = useRef(null);

  const fetchHighLightData = useCallback(async () => {
    try {
      const docRef = doc(
        db,
        `${process.env.REACT_APP_DB_ENV}/${dynamicId}/HighLightSettings/HighLight`
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setHighLight(data.highLightSettings || initialHighLight);
      }

      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setHighLight(data.highLightSettings || initialHighLight);
        }
      });

      return unsubscribe;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [dynamicId]);

  useEffect(() => {
    if (!dynamicId) return;

    const fetchHighLightDataAndSubscribe = async () => {
      const unsubscribe = await fetchHighLightData();
      return unsubscribe;
    };

    const unsubscribe = fetchHighLightDataAndSubscribe();

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dynamicId, fetchHighLightData]);

  const fetchData = useCallback(async () => {
    try {
      const docRef = doc(db, `${process.env.REACT_APP_DB_ENV}/${dynamicId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setImages(data.images || []);
      }

      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setImages(data.images || []);
        }
      });

      return unsubscribe;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [dynamicId]);

  useEffect(() => {
    if (!dynamicId) return;

    const fetchDataAndSubscribe = async () => {
      const unsubscribe = await fetchData();
      return unsubscribe;
    };

    const unsubscribe = fetchDataAndSubscribe();

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dynamicId, fetchData]);
  const fetchSettings = useCallback(() => {
    try {
      const docRef = doc(
        db,
        `${process.env.REACT_APP_DB_ENV}/${dynamicId}/PhotowallSettings/Settings`
      );

      // Set up a real-time listener with onSnapshot
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setSettings(data.photoWallSettings || initialSettings);
        } else {
          setSettings(initialSettings); // Reset to initial settings if the document doesn't exist
        }
      });

      // Return the unsubscribe function to clean up the listener when component unmounts
      return unsubscribe;
    } catch (err) {
      setError(err.message);
    }
  }, [dynamicId]);

  const getQrCode = useCallback(async () => {
    try {
      const result = await axios({
        baseURL: process.env.REACT_APP_API_URL,
        method: "GET",
        url: `/pub/event/qr-code/${dynamicId}`,
      });
      setQrCode(result.data.url);
    } catch (err) {
      setError(err.message);
    }
  }, [dynamicId]);

  useEffect(() => {
    if (!dynamicId) return;
    fetchSettings();
    getQrCode();
  }, [dynamicId, fetchSettings, getQrCode]);

  const preloadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
    });
  };

  useEffect(() => {
    if (images.length === 0 || !settings) return;

    const interval = setInterval(async () => {
      setFade(false);

      await preloadImage(
        `https://storage.googleapis.com/${dynamicId}/thumbnails/${
          images[(currentIndex + 1) % images.length]
        }`
      );

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 500); // Allow time for fade-out before changing the image
    }, settings.imageDuration * 1000);

    return () => clearInterval(interval);
  }, [images, settings, currentIndex, dynamicId]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        !!(
          document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        )
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const handleFullscreen = () => {
    if (photowallRef.current.requestFullscreen) {
      photowallRef.current.requestFullscreen();
    } else if (photowallRef.current.mozRequestFullScreen) {
      photowallRef.current.mozRequestFullScreen(); // Firefox
    } else if (photowallRef.current.webkitRequestFullscreen) {
      photowallRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
    } else if (photowallRef.current.msRequestFullscreen) {
      photowallRef.current.msRequestFullscreen(); // IE/Edge
    }
    setIsFullscreen(true);
  };

  const loadingContent = useMemo(() => {
    return (
      <div className="loading-wrap">
        <h2 className="loading-text">Setting up Photowall...</h2>
        <Lottie
          animationData={loadingLottie}
          className="lottie-expand"
          style={{ width: "300px" }}
        />
        <img
          src={"/catchmemowhite.png"}
          alt={`qr-code`}
          style={{ width: "200px" }}
        />
      </div>
    );
  }, []);

  const photowallContent = useMemo(() => {
    const defaultBackground =
      "linear-gradient(45deg,#f09433 0%,#e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)";

    let backgroundStyle;

    if (settings?.dynamicWall) {
      // If dynamicWall is true, always show a black background
      backgroundStyle = "#060D0D";
    } else if (settings?.background) {
      // If a background is provided in settings, use it
      backgroundStyle = `url(${settings.background})`;
    } else if (
      !settings?.background &&
      !settings?.dynamicWall &&
      images[currentIndex]
    ) {
      backgroundStyle = `url(https://storage.googleapis.com/${dynamicId}/thumbnails/${images[currentIndex]})`;
    } else {
      // Fallback to the default background
      backgroundStyle = defaultBackground;
    }
    return (
      <div className="photowall" ref={photowallRef}>
        {error && !images.length && (
          <div
            className="no-img-text flex items-center justify-center text-center flex-col"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Something went wrong! Contact us!
            <br />
            Hello@catchmemo.com
          </div>
        )}

        {settings.eventPlan === "Free" && (
          <div className="branding" style={{ zIndex: "5" }}>
            <h2 className="branding-text">Powered by </h2>
            <img
              src={"https://catchmemo.com/images/catchmemologo.svg"}
              alt={`qr-code`}
              className="branding-img"
            />
          </div>
        )}
        {!isFullscreen && (
          <div
            className="fullscreen-wrap"
            onClick={handleFullscreen}
            style={{ zIndex: "5" }}
          >
            <Lottie animationData={expandLottie} className="lottie-expand" />
            <h2 className="fullscreen-text">Click to Enter Fullscreen</h2>
          </div>
        )}
        {!loading && !error && images.length === 0 && (
          <div className="no-images-wrap">
            <h2 className="no-img-text">
              No images found! <br />
              Please upload images through the event page.
            </h2>
          </div>
        )}

        <div
          className="overlay"
          style={{
            opacity: settings.overlay,
          }}
        />

        {settings?.dynamicWall && images?.length > 0 && (
          <DynamicPhotoWall
            dynamicId={dynamicId}
            images={images}
            settings={settings}
          />
        )}

        {settings?.floatingImages && images?.length > 0 && (
          <FloatingImages dynamicId={dynamicId} images={images} />
        )}

        <div
          className="photowall-full-background"
          style={{
            background: backgroundStyle,
            filter: settings.filterBlur,
            transition: "opacity 0.5s ease-in-out",
          }}
        />
        {!highLight.highLightImg && !highLight.message && images.length > 0 && (
          <img
            src={`https://storage.googleapis.com/${dynamicId}/thumbnails/${images[currentIndex]}`}
            alt={`Image ${currentIndex}`}
            className={`${fade ? "fade-in" : "fade-out"} img-content`}
            style={{ transition: "opacity 0.5s ease-in" }}
          />
        )}

        {highLight.highLightImg && (
          <img
            src={`${highLight.highLightImg}`}
            alt={`Image hightlight`}
            className={`img-content`}
          />
        )}

        {highLight.message && (
          <div
            className={` highlight-msg`}
            style={{
              animation: `${highLight?.animation} ${highLight?.animationSpeed}s infinite`,
            }}
          >
            <h2
              style={{
                animation: `${highLight?.textAnimation} ${highLight?.animationSpeed}s infinite`,
              }}
            >
              {highLight.message}
            </h2>
          </div>
        )}
        {settings.showQr && qrCode && (
          <img
            src={qrCode}
            alt={`qr-code`}
            className="qr-code-img"
            style={{
              left: settings.qrCodePosition === "left" ? "0" : "",
              right: settings.qrCodePosition === "right" ? "0" : "",
              margin: "2rem",
            }}
          />
        )}
        {settings.logo && (
          <div
            className="logo-img"
            style={{
              margin: "2rem",
              backgroundImage: `url(${settings.logo})`,
              width: "150px",
              height: "150px",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "5%",
            }}
          />
        )}
      </div>
    );
  }, [
    error,
    images,
    settings,
    currentIndex,
    fade,
    isFullscreen,
    qrCode,
    handleFullscreen,
  ]);

  if (loading && !qrCode) {
    return loadingContent;
  }

  return photowallContent;
});

export default Photowall;
