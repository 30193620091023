import React, { useState, useEffect, useMemo } from "react";

const FloatingImages = ({ images, dynamicId }) => {
  // Helper function to shuffle and split images into unique left and right groups
  const shuffleAndSplitImages = (imageArray) => {
    const shuffled = [...imageArray].sort(() => Math.random() - 0.5);
    const midpoint = Math.ceil(shuffled.length / 2);
    return {
      leftImages: shuffled.slice(0, midpoint),
      rightImages: shuffled.slice(midpoint),
    };
  };

  // State to hold image sets for the left and right columns
  const [imageSets, setImageSets] = useState(() =>
    images.length
      ? shuffleAndSplitImages(images)
      : { leftImages: [], rightImages: [] }
  );

  // Set up an immediate effect to initialize and then update images every 15 seconds
  useEffect(() => {
    if (images.length === 0) return;

    // Set initial images immediately
    setImageSets(shuffleAndSplitImages(images));

    // Set up the interval for updating images every 15 seconds
    const interval = setInterval(() => {
      setImageSets(shuffleAndSplitImages(images));
    }, 15000);

    return () => clearInterval(interval);
  }, [images]);

  // Generate stable image indices for each side
  const generateImageIndices = (imageArray, count) =>
    Array.from({ length: count }, (_, i) => i % imageArray.length);

  const leftImageIndices = useMemo(
    () => generateImageIndices(imageSets.leftImages, 5),
    [imageSets.leftImages]
  );

  const rightImageIndices = useMemo(
    () => generateImageIndices(imageSets.rightImages, 5),
    [imageSets.rightImages]
  );

  const generateInitialStyles = (count) =>
    Array.from({ length: count }).map((_, index) => ({
      left: index % 2 === 0 ? "0%" : "auto",
      right: index % 2 !== 0 ? "0%" : "auto",
      top: "100vh",
      animationDelay: `${index * 3}s`,
    }));

  // Stable styles created once for each side
  const leftImageStyles = useMemo(() => generateInitialStyles(5), []);
  const rightImageStyles = useMemo(() => generateInitialStyles(5), []);

  // If there are no images, display a fallback message or return null
  if (images.length === 0) {
    return <></>;
  }

  return (
    <div className="float-container">
      <div className="left-container-float">
        {leftImageIndices.map((imageIndex, index) => (
          <div
            key={`left-${index}`}
            className="floating-image"
            style={{
              backgroundImage: `url(https://storage.googleapis.com/${dynamicId}/thumbnails/${imageSets.leftImages[imageIndex]})`,

              borderRadius: "19px",
              ...leftImageStyles[index], // Use precomputed style for consistent position/size
            }}
          />
        ))}
      </div>

      <div className="right-container-float">
        {rightImageIndices.map((imageIndex, index) => (
          <div
            key={`right-${index}`}
            className="floating-image"
            style={{
              backgroundImage: `url(https://storage.googleapis.com/${dynamicId}/thumbnails/${imageSets.rightImages[imageIndex]})`,

              borderRadius: "19px",
              ...rightImageStyles[index], // Use precomputed style for consistent position/size
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default FloatingImages;
